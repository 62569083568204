<template>
  <div class="phonebind">
    <div class="title">绑定手机号</div>
    <div class="phonewrap">
        <div class="div1">
            <input type="text" placeholder="手机号"/>
        </div>
        <div class="div2" >
            <input type="text" placeholder="验证码" class="teshu"/>
            <div class="time" @click="countDown">{{content}}</div>
        </div>
        <button class="regist" @click="onSubmit">立即绑定</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'phonebind',
  components: { },
  data() {
    return{
       content: '发送验证码',  // 按钮里显示的内容
       totalTime: 10,      //记录具体倒计时时间
       canClick: true //添加canClick
    }
  },
  methods: {
    countDown () {
        if (!this.canClick) return  
            this.canClick = false
            this.content = this.totalTime + 's'
            let clock = window.setInterval(() => {
                this.totalTime--
                this.content = this.totalTime + 's'
            if (this.totalTime < 0) {
                window.clearInterval(clock)
                this.content = '重新发送'
                this.totalTime = 10
                this.canClick = true  //这里重新开启
            }
        },1000)
    },
    onSubmit () {
        this.$router.push("/Basic");
    }

  },
}
</script>

<style lang='scss'>
  @import "../../../assets/scss/common.scss";
  @import "../../../assets/scss/reset.scss";
  .phonebind{
    padding: 30px 20px;
    .title{
      font-size: 18px;
      color: #333333;
      padding-bottom: 40px;
    }
    .phonewrap{
        height: 600px;
        padding-left: 80px;
        .div1{
            width: 762px;
            height: 45px;
            font-size: 18px;
            background: url(../../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
            color: #999999;
            margin-bottom: 30px;
            input{
                padding-left: 50px;
                width:284px;
                height: 43px;
                border: 0;
                border: 1px solid #eaeaea;
                background: none;
                border-radius: 5px;
                float: left;
            }
        }
        .div2{
            width: 337px;
            height: 45px;
            margin-bottom: 30px;
            .teshu{
                width: 150px;
                height: 40px;
                border-radius: 5px;
                color: #eaeaea;
                border: 1px solid #eaeaea;
                background: url(../../../assets/icon/yanzhengma.png) no-repeat 13px 13px;
                float: left;
                padding-left: 54px;
            }
            .time{
                width: 117px;
                height:45px;
                background: #0db168;
                float: left;
                margin-left: 12px;
                border-radius: 5px;
                text-align: center;
                line-height: 45px;
                color: #ffffff;
            }
        }
        .regist{
            width:337px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            background: #0db168;
            border: none;
            font-size: 16px;
            color: #fff;                                                               
            border: 0;
            border-radius: 5px;
        }
    }
  }
</style>
